

:root {
  --maincolor1: rgb(148, 77, 255);
  --seccolor1: rgb(148, 77, 255);
  --maincolor: #7561ff;
  --seccolor: rgb(81, 156, 255);
  --maincolor3: #43e54e;
  --seccolor3: #f77f57;
}
.App {
  text-align: center;
}

body{
  background-image: url("./images/background.avif");
  /*background:linear-gradient(90deg,#8b76fe4d,#1876f1b8);*/

  background-size: cover;
  color: var(--maincolor);
  background-color: #0c0d08;

background-attachment: fixed;
  height: 100%;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.pokemon-card-id small{
  font-size: 10px;
}
p{
  color: #FFFFFF;
}
.modal-body p{
  color: #333333;
}
.pokemon-card-id{
  display: block;
  min-width: 40px;
  background-color: var(--seccolor);
  border-radius: 9px;
  color: #fff;
  font-size: 15px;
  position: absolute;
  top: 6px;
  left: 6px;
  text-align: center;
  line-height: 25px;
  border: 1px solid rgba(81, 156, 255, 0.702);
  background: rgba(81, 156, 255);
  transition: all 0.5s;
  padding: 0px 15px;
}

.pokemon-card {
 /* background-color: rgba(255, 255, 255, 0.8);*/
  background:linear-gradient(90deg,#8b76fe4d,#1876f1b8);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(15px);
  border: 0px solid var(--maincolor);
  border-color: var(--seccolor);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  /*box-shadow: 0px 2px 10px rgba(81, 156, 255, 0.2);*/
  color: #ffffff;
  padding: 5px;
  transition: all 2s;
  position: relative;

}
.pokemon-card:hover {
  box-shadow: 0px 2px 20px rgba(81, 156, 255, 0.8);
  transition: all 0.5s;
}
.pokemon-card:hover .pokemon-card-id {
  box-shadow: 0px 2px 10px rgba(81, 156, 255, 0.6);
  transition: all 0.5s;
  background: rgba(81, 156, 255, 0.8);

 
}

.card-img-top {
  object-fit: contain;
  height: 300px; /* Set a fixed height for your images */
  border-radius: 10px;
  box-shadow: 0px 2px 20px rgba(81, 156, 255, 0.8);
}

.pokemon-card-title {
  color: #FFFFFF;
    text-shadow: var(--maincolor) 1px 0 10px;
  text-align: center;
  border-radius: 10px;
  padding: 0px 0;
  font-size: 25px;
}

.pokemon-card-body {
  padding: 10px;
}

.pokemon-card-footer {
  
  
  color: white;
  text-align: center;
  padding: 6px 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.buton{
  background: rgba(81, 156, 255, 0.3);
  border: 1px solid rgba(81, 156, 255, 0.1);
  border-radius: 10px;
  color: #FFFFFF;
  text-shadow: var(--maincolor) 1px 0 10px;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  padding: 11px 10px;
  transition: all 0.3s ease-in-out;
  ;
  
}
.buton:hover{
 
  transition: all 0.5s;
  border: 1px solid rgba(81, 156, 255, 0.3);
  background: rgba(81, 156, 255, 0.6);
}

.butonDisabled{
  border: 0px;
  background: transparent !important;
  background-color: transparent !important;
  color: var(--maincolor) !important;
}


.loaderSmall{
  display: block;
  color: var(--seccolor);
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-top: 10px;
  
}

.loaderBig{
  color: var(--seccolor);
  width: 100px;
  height: 100px;
  margin-top: 100px;
  
}

.loaderMsg{
  color: var(--seccolor);
  font-size: 18px;
  margin-top: 50px;
}

.status-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}
.green-circle {
  background-color: #01a201;
}

.buy-token-container {
  color: white;

  width: 100%;
  max-width: 100%;
  margin: auto;
  
  box-shadow: 0px 2px 10px rgba(81, 156, 255, 0.3);
  border-radius: 10px;
  padding: 20px;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}

.buy-token-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: var(--maincolor);
  font-weight: 200;
}
h4{
   
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 30px;
    text-transform: uppercase;
    color: var(--maincolor);
    text-shadow: var(--maincolor) 1px 0 10px;
    
}
h3{
   
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 20px;
    text-transform: uppercase;
    color: var(--maincolor);
    text-shadow: var(--maincolor) 1px 0 10px;
    
}
h5{
  color: #FFFFFF !important;
  font-weight: 200 !important;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 20px;
  text-shadow: var(--maincolor) 1px 0 10px;
}

.buy-node, .buy-token {
  background: #252e4f;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.token-exchange-info {
  
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 0px;
  padding-top: 30px;
  color: #FFFFFF;
  background: transparent;
}

.wallet-balance {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.wallet-button {
  background: #313c5e;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.balance-info {

  padding: 10px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
  color: #FFFFFF;
}

.swap-form {

  padding: 20px;
  border-radius: 10px;
}

.input-group {
  
  margin-bottom: 20px;
}

.input-group input,
.input-group select {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(28, 140, 239, 0.665);
  box-shadow: 0px 2px 10px rgba(81, 156, 255, 0.2);
  border-radius: 15px;
  color: #FFFFFF;
  font-size: 20px;
  padding: 10px 10px;
  border-radius: 10px !important;
  width: 100%;
  text-align: center;
}

.balanceDisplay{
  display: inline-block;
  color: #FFFFFF;
    text-shadow: var(--maincolor) 1px 0 10px;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 0px;
  font-size: 18px;
  margin-right: 10px;
}

.balanceDisplay img{
  width: 30px;
  margin-right: 15px;
}

.max-button {
  background: transparent;
  color: var(--maincolor);
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}


.gas-info {
  font-size: 10px;
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  margin-top: 20px;
}

.swap-button {
  background: var(--seccolor);
  color: var(--maincolor);
    text-shadow: var(--maincolor) 1px 0 10px;
  border: none;
  padding: 10px 20px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
}

.menuBtn, .nav-link{
  display: inline-block;
  padding: 10px 20px;
  width: auto;
  text-align: left;
  font-size: 18px;
  background-color: transparent;
  margin-top: 10px;
  border: 0px;
  margin-right: 15px;
  color: #FFFFFF;
    text-shadow: var(--maincolor) 1px 0 10px;
  transition: all 0.3s;
  /* border-bottom: 1px solid var(--maincolor);*/
}

.menuBtn:hover, .nav-link:hover{
  color: var(--seccolor);
  background-color: transparent;
  border: 0px;
  transition: all 0.3s;
}

.nav-link:focus {
  color: var(--seccolor);
}

.tokenIconColor{
  color: var(--maincolor);;
}

.loaderScreen{

  display: block;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-backdrop-filter: blur(55px);
  backdrop-filter: blur(55px);
}
h2{
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 0px;
  font-size: 28px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
}

.alert{
  padding: 20px;
}


.iframe-container-top {
  position: relative;
  width: 100%;
  height: 50px;
}

.iframe-container-top iframe {

  width: 100%;
  height: 55px;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none; /* Optionally, to remove iframe border */
  border-radius: 10px;
  box-shadow: inset 0 -20px 30px rgba(81, 156, 255, 0.71);
  border:0px solid rgba(81, 156, 255, 1);

}

::placeholder {
  color: #cccccc;
  opacity: 1; /* Firefox */
}


.smallBtn{
  display: inline-block;
  width: auto;
  padding: 5px 25px;
  margin-left: 5px;
}


.plusButton{
  display: block;
  border: 0px;
  margin: 0 auto;
  width: 130px;
  margin-top: 100px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(15px);
  border-radius: 15px;
  box-shadow: 0px 2px 10px rgba(81, 156, 255, 0.2);
  padding: 30px 10px;
  
}

.plusButton img{
  width: 50%;
  margin-bottom: 15px;
}

.plusButton:hover{
  background: transparent;
  border:0px
}




.node-item {
  display: flex;
  align-items: center; /* Ensures vertical alignment is centered for all elements */
  padding: 10px;
  margin-bottom: 10px;
  
  background:linear-gradient(90deg,#8b76fe4d,#1876f1b8);
  -webkit-backdrop-filter: blur(25px);
   backdrop-filter: blur(15px);
   border: 0px solid var(--maincolor);
   border-color: var(--seccolor);
   border-radius: 10px;
   /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);*/
   box-shadow: 0px 2px 10px rgba(81, 156, 255, 0.2);
   color: #ffffff;
   transition: all 2s;
}

.node-item:hover {
  box-shadow: 0px 2px 20px rgba(81, 156, 255, 0.8);
  transition: all 0.5s;
}

.node-image img {
  width: 50px; /* Adjust the size based on your design */
  height: 50px;
  margin-right: 15px; /* Provides spacing between the image and the text */
  box-shadow: 0px 2px 20px rgba(81, 156, 255, 0.8);
  border-radius: 10px;
  padding: 5px;
}

.node-info {
  display: flex;
  flex-grow: 1;
  align-items: center; /* Align text content vertically in the center */
}

.node-id, .node-name, .node-rewards {
  margin-right: 15px; /* Provides spacing between text elements */
}

.node-id {
  font-size: 25px;
  color: #FFFFFF;
    text-shadow: var(--maincolor) 1px 0 10px;
  font-weight: 500;
}

.node-name {
  font-size: 18px;
  color: #FFFFFF;
    text-shadow: var(--maincolor) 1px 0 10px;
 
}

.node-rewards {
  font-size: 14px;
  color: #FFFFFF;
    text-shadow: var(--maincolor) 1px 0 10px;
  flex-grow: 1; /* Allows this element to take up any available space */
}

button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.bg-success{
  background-color:rgba(81, 156, 255, 0.8) !important;
}
.bg-danger{
  background-color:rgba(244, 47, 47, 0.8) !important;
}

.progress{

background-color:rgba(81, 156, 255, 0.13);
box-shadow: 0px 2px 20px rgba(81, 156, 255, 0.8);
}



.navbar-toggler{
  background-color: var(--maincolor);
}


.custom-select {
  border-radius: 10px !important;
}

.custom-select:focus {
  outline: none;
  /* Remove focus outline */
  border-color: rgb(81, 156, 255);

}

.additional-p {
  padding: 32px 28px;
}


.progress-bar {
  color: transparent !important;
}


.footerFixed {
  /* background-color: #f8f9fa; */
  background:linear-gradient(90deg,#8b76fe4d,#1876f1b8);
  padding: 18px 0;
  position: sticky;
  width: 100%;
  bottom: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
  font-size: 14px;
}

.social-icon {
  margin: 0 10px;
  color: rgb(255, 255, 255);
  transition: all 0.3s;
  text-decoration: none;
  opacity: 0.6;
}

@media screen and (max-width: 400px) {
  .social-icon {
    margin: 0 8px;
    font-size: 13px;
  }
}

.twitter:hover {
  opacity: 1;
}

.telegram:hover {
  color: #0088cc;
  opacity: 1;
}

.instagram:hover {
  color: #E1306C;
  opacity: 1;
}

.platform:hover {
  color: #944dff;
  opacity: 0.9;
}

.station:hover {
  color: rgb(237, 77, 255);
  opacity: 0.8;
}

.table {
  background-color: transparent; /* Makes the table transparent */
  color: white; /* Sets font color to white */
}

.table th, 
.table td {
  border-color: rgba(255, 255, 255, 0.3); /* Light white border for better visibility */
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.1); /* Subtle color for striped rows */
}

.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Highlight row on hover */
}


/* Add this CSS to your stylesheet */
@media screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
  .show-on-mobile {
    display: inline-block;
  }
  .width-on-mobile{
    width: 100%;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 769px) {
  .hide-on-mobile {
    display: inline-block;
  }
  .show-on-mobile {
    display: hide;
  }
  .width-on-mobile{
    width: auto;
  }
}


/* Countdown Top Bar Styling */
.countdown-top-bar {
  position: relative;
  top: 0;
  width: 100%;
  padding: 10px 0;
  font-size: 1em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1000;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(90deg, #8a76fe7a, #1876f1, #8a76fe3a);
  background-size: 300% 300%;
  animation: gradientShift 8s ease infinite;
}

/* Countdown Text Styling */
.countdown-text {
  margin: 0 10px;
}

/* Gradient Animation */
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#dexscreener-embed{position:relative;width:100%;padding-bottom:125%;}@media(min-width:1400px){#dexscreener-embed{padding-bottom:65%;}}#dexscreener-embed iframe{position:absolute;width:100%;height:100%;top:0;left:0;border:0;}